<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Confrim</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="red--text text-uppercase font-weight-bold">Peringatan !</div>
            <div>
              data yang sudah di input pada form akan di kosongkan jika dilakukan penggantian tipe
              dari PO ke DO atau DO ke PO.
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="close">
          No
        </v-btn> -->
        <v-btn color="error" @click="confirm">
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-confirm",
  props: {
    dialog: Boolean,
  },
  methods: {
    confirm() {
      this.$emit("reset");
      this.$emit("close");
    },
    // close() {
    //   this.$emit("close");
    // },
  },
};
</script>

<style></style>
